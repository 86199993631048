/** Media Queries for Width **/

@media screen and (max-width:1800px) {
  .patient-num {
    padding-left: 18%;
  }
}

@media screen and (max-width: 1580px) {
  .custom-container {
    max-width: 96%;
  }

  .healtvc-card-title,
  .healtvc-apptime,
  .pc-doctor-notes {
    font-size: 1vw;
  }

  .patient-num {
    padding-left: 15%;
  }

  h6.healtvc-apptime>span.btn {
    font-size: 0.9vw;
  }

  .card-light-gray.card-subtitle {
    font-size: 1.1vw;
  }

  button.button-healthvc {
    font-size: 1.07vw;
  }

  .pl-alert .alert-heading {
    font-size: 1.02vw;
  }

  .pl-alert p {
    font-size: 1.26vw;
  }

  .video-btn>.img-btn {
    font-size: 1.2vw;
    font-weight: 500;
  }

  .video-btn>.img-btn>img {
    width: 1.6vw;
  }

  .audio-btn>.img-btn>img {
    width: 1.3vw;
  }

  label.label1 {
    font-size: 1.22vw;
    font-weight: 500;
    margin-bottom: 0px !important;
    float: none !important;
  }

  .doc-upload.label1 {
    font-size: 1.25vw;
    margin-bottom: 0 !important;
  }

  .ck-editor {
    margin-top: 5px !important;
  }

  .publisher-elem {
    width: 6.3vw;
    /* background-size: 2.5vw; */
  }

  .control-btn img {
    width: 2.5vw;
  }

  .prescription-notes>.complete-btn {
    margin-top: 20px !important;
    font-size: 1.01vw;
  }

  /** Calendar CSS **/

  .my-calendar .fc-body table thead td span {
    font-size: 1.15vw;
  }

  .my-calendar .fc-body table tbody td .fc-event .fc-title {
    font-size: 1.15vw;
  }

  .my-calendar .fc-body table tbody td a {
    min-width: 2.8vw;
  }

  /* .my-calendar .fc-body table thead td span {
    min-width: 2.8vw;
    padding-left: 10px;
    padding-right: 10px;
  } */

  /** Calendar CSS **/
}

@media screen and (max-width: 1480px) {
  h6.healtvc-apptime>span.btn {
    margin-left: 5px;
  }

  .healtvc-apptime>span:first-child img {
    height: 12px;
    max-width: 18px;
    margin-right: 4px;
  }

  .healtvc-apptime>span:first-child {
    margin-right: 4px;
    font-size: 12px;
    /* font-size: 14px; */
  }

  .healtvc-apptime>span.late-span {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1420px) {
  section.section-subheader {
    padding: 0.5vw 0;
  }

  .healtvc-card-title,
  .healtvc-apptime,
  .pc-doctor-notes {
    font-size: 1vw;
  }

  /* h6.healtvc-apptime>span.btn {
    margin-left: 7px !important;
  } */

  .card-light-gray.card-subtitle {
    font-size: 1.05vw;
  }

  button.button-healthvc {
    font-size: 1.1vw;
  }

  .healtvc-card-title,
  .healtvc-apptime {
    margin-bottom: 5px !important;
  }

  .card-bodycustom>div>div:first-child .healtvc-card-title {
    margin-bottom: 0.6rem !important;
    /* font-size: 1vw; */
  }

  .publisher-elem {
    width: 7.3vw;
  }

  /** Calendar CSS **/

  .my-calendar .fc-body table thead td span {
    font-size: 1.3vw;
  }

  .my-calendar .fc-body table tbody td .fc-event .fc-title {
    font-size: 1.3vw;
  }

  /* .my-calendar .fc-body table tbody td a {
    min-width: 2.8vw;
  }

  .my-calendar .fc-body table thead td span {
    min-width: 2.8vw;
    padding-left: 10px;
    padding-right: 10px;
  } */

  /** Calendar CSS **/

  button.btn.btn-worklist {
    line-height: 15px;
    padding: 5px 10px;
    /* font-weight: 400 !important; */
  }

  .pc-doctor-notes.pcd-notes {
    line-height: 22px;
  }

  .btn-outline-danger-view {
    font-size: 1.1vw;
    padding: 3px 14px !important;
    border-radius: 5px !important;
    font-weight: 500 !important;
  }
}

@media screen and (max-width: 1350px) {
  .tabs-healthvccl .nav-link {
    font-size: 1.3vw;
  }

  h6.healtvc-apptime>span.btn {
    font-size: 1vw;
    padding: 0.09em 0.4em;
  }

  .pl-alert {
    padding-top: 1vw !important;
    padding-bottom: 1vw !important;
  }

  .pl-alert .alert-heading {
    font-size: 1.2vw;
  }

  .pl-alert p {
    font-size: 1.2vw;
  }

  .pl-alert button {
    font-size: 1.1vw;
  }

  .my-calendar .fc {
    padding-top: 5px;
  }

  .my-calendar .fc-ltr .fc-view .fc-day-top.fc-today .fc-day-number {
    width: 60px;
  }

  .my-calendar .fc-body table tbody td .today.fc-event {
    width: 60px;
  }

  .section-subheader .sub-calendar-icon {
    height: 1.8vw;
  }

  .popup-content {
    width: 45% !important;
  }

  .cancel-popbig.cancel-pop-content {
    width: 60% !important;
  }

  .healthvcst-count {
    font-size: 1.5vw;
  }

  .tabs-healthvccl .nav-pills {
    margin-top: 12px !important;
    margin-bottom: 20px !important;
  }

  /* .subHead-list > div:not(:last-child) {
    margin-right: 0.8vw !important;
  }

  .subHead-list > div:not(:first-child) {
    padding-left: 0.8vw !important;
  } */

  .healtvc-apptime>span.late-span {
    font-size: inherit !important;
    margin-left: 4px;
  }

  /** Header **/
  #nav-healthdropdown {
    font-size: 1.2vw;
  }

  .vc-name {
    font-size: 1.3vw;
    font-weight: 500;
  }

  /** Header **/

  .healthvc-tick {
    height: 16px;
    width: 8px;
    margin-right: 10px;
    margin-left: -19px;
  }

  .healtvc-card-title,
  .healtvc-apptime,
  .pc-doctor-notes {
    font-size: 1.10vw;
  }

  .subHead-list>div {
    font-size: 1.3vw;
    font-weight: 400;
  }

  section.section-subheader {
    padding: 0.6vw 0;
  }

  .section-subheader .calendar-icon+span {
    font-size: 1.3vw;
  }

  .card-bodycustom {
    padding: 0.6rem 0.8rem;
  }

  .card-light-gray.card-subtitle {
    font-size: 1vw;
  }

  .button-variant-red {
    margin-bottom: 7px !important;
  }

  button.button-healthvc {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .calendar-cardheader .card-title {
    font-size: 1.4vw;
    font-weight: 400;
  }

  .calendar-cardheader .card-text {
    font-size: 1vw;
  }

  .scroll_loader .card-body .card-title {
    font-size: 1.22vw;
    font-weight: 400;
  }

  .scroll_loader .card-body .card-text {
    font-size: 1vw;
  }

  .section-subheader {
    height: 43px;
  }

  .slots-btn>div button {
    font-size: 1.3vw;
  }

  .pc-doctor-notes img {
    height: 25px !important;
  }

  .my-calendar .fc-toolbar.fc-header-toolbar h2 {
    font-size: 1.4vw;
  }

  .text-feild {
    padding: 4px 8px;
  }

  .doctor-notes {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .doctor-notes label.label1 {
    margin-top: 4px !important;
  }

  .doc-notes-text {
    font-size: 1.15vw;
    height: calc(100% - 30px);
    line-height: 1.8vw;
  }

  .ck-content {
    font-size: 1.15vw;
  }

  .text-feild1 {
    font-size: 1.15vw;
  }

  /** Header **/
  #nav-healthdropdown {
    font-size: 1.2vw;
  }

  .vc-name {
    font-size: 1.3vw;
    font-weight: 500;
  }

  /** Header **/

  .healthvc-tick {
    height: 16px;
    width: 8px;
    margin-right: 10px;
    margin-left: -19px;
  }

  /* .healtvc-card-title,
  .healtvc-apptime,
  .pc-doctor-notes {
    font-size: 1.15vw;
  } */

  .subHead-list>div {
    font-size: 1.3vw;
    font-weight: 400;
  }

  section.section-subheader {
    padding: 0.6vw 0;
  }

  .section-subheader .calendar-icon+span {
    font-size: 1.3vw;
  }

  .card-bodycustom {
    padding: 0.6rem 0.8rem;
  }

  .chat_icon_wrapper {
    margin-left: 10px;
  }

  .chat_icon_wrapper img {
    width: 22px;
  }

  /* .card-light-gray.card-subtitle {
    font-size: 1.13vw;
  } */

  .button-variant-red {
    margin-bottom: 7px !important;
  }

  button.button-healthvc {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .calendar-cardheader .card-title {
    font-size: 1.4vw;
    font-weight: 400;
  }

  .calendar-cardheader .card-text {
    font-size: 1vw;
  }

  .scroll_loader .card-body .card-title {
    font-size: 1.22vw;
    font-weight: 400;
  }

  .scroll_loader .card-body .card-text {
    font-size: 1vw;
  }

  .section-subheader {
    height: 43px;
  }

  .slots-btn>div button {
    font-size: 1.3vw;
  }

  .pc-doctor-notes img {
    height: 25px !important;
  }

  .my-calendar .fc-toolbar.fc-header-toolbar h2 {
    font-size: 1.4vw;
  }

  .text-feild {
    padding: 4px 8px;
  }

  .doctor-notes {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .doctor-notes label.label1 {
    margin-top: 4px !important;
  }

  .doc-notes-text {
    font-size: 1.15vw;
    height: calc(100% - 30px);
    line-height: 1.8vw;
  }

  .ck-content {
    font-size: 1.15vw;
  }

  .text-feild1 {
    font-size: 1.15vw;
  }

  .prfl-sub-right {
    font-size: 1.3vw;
  }

  .prfl-sub-left {
    font-size: 1.3vw;
  }

  .pc-top+p {
    font-size: 1.3vw;
  }

  .profile-edit-btn>a {
    font-size: 1.3vw;
  }

  .profile-edit-btn>a>img {
    width: 12px;
  }

  .profile-name {
    font-size: 1.3vw;
  }

  .profile-des {
    font-size: 1.3vw;
  }

  .patient-num {
    padding-left: 13%;
  }

  label.label1 {
    font-size: 1.03vw;
  }

  .cf_wrapper>.row:nth-child(3) span {
    font-size: 12px;
  }

  .patient-vitals .doc-notes-text {
    margin-left: -8px;
    margin-right: -8px;
    width: calc(100% + 16px);
  }

  .patient-vitals .label1 {
    margin-left: -8px !important;
    margin-right: -8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

@media screen and (max-width:1284px) {
  .popup-content.homecare-charts-modal-content {
    width: 98% !important;
  }

  .charts-bg {
    padding: 10px;
  }

  .box-alert.show.dropdown.nav-item .dropdown-menu.show {
    margin-top: 1.25rem !important;
  }

  .btn_allergies {
    width: 93.3%;
  }

  .progressnotes_accordion>.card>.card-header {
    font-size: 1.35vw;
    padding: 10px 15px;
    height: 47px;
  }

  .nurse-content,
  .doc-content {
    padding: 1rem !important
  }
}

@media screen and (max-width:1140px) {
  .charts-bg {
    padding: 4px;
  }
}

@media screen and (max-width:1124px) {
  .patient-num {
    padding-left: 9%;
  }

  .mobilenumbers {
    width: 40vw !important;
  }

  .popup-content {
    width: 60% !important;
  }


  .allergies-col {
    height: 60vh;
  }

  .known-allergies-data {
    height: 42vh;
  }
}

@media screen and (max-width:800px) {
  .btn_allergies {
    width: 76%;
  }
}

@media screen and (min-width:1120px) {
  .reading {
    margin: 2rem 0 2rem 1rem;
    height: 36px;
    width: 53%;
    border: 1px solid #b6b6b678;
  }

  .reading.bp {
    width: 41%;
  }
}

@media screen and (min-width:1272px) {
  .reading {
    margin: 2rem 0 2rem 1rem;
    height: 36px;
    width: 55%;
    border: 1px solid #b6b6b678;
  }

  .reading.bp {
    width: 46%;
  }
}

@media screen and (max-width:1390px) {
  .border-text-reading.rbs {
    width: 74% !important;
  }

  .pv-common input {
    max-width: 67px !important;
  }

  .btn_allergies {
    width: 93%;
  }
}

@media screen and (max-width:1450px) {
  .border-text-reading.rbs {
    width: 70%;
  }

  .pv-common input {
    max-width: 71px;
  }

  .box-alert.show.dropdown.nav-item .dropdown-menu.show {
    margin-top: 1.6rem !important;
  }

  span.alert-box {
    margin-left: 30px !important;
  }

  .pv-common input {
    max-width: 71px !important;
  }
}

@media screen and (max-width:1386px) {
  span.alert-box {
    margin-left: 25px !important;
    padding: 4px 25px;
  }

  .alert-box img {
    width: 22px;
  }
}

@media screen and (max-width:1340px) {
  span.alert-box {
    margin-left: 21px !important;
  }
}

@media screen and (max-width:1520px) {
  span.alert-box {
    margin-left: 35px;
  }

  .pv-common input {
    max-width: 75px;
  }

  /* .tooltip-img {
    margin-top: -13px !important;
  } */
}

@media screen and (min-width:1570px) {
  .reading {
    margin: 2rem 0 2rem 1rem;
    height: 36px;
    width: 45%;
    border: 1px solid #b6b6b678;
  }

  .reading.bp {
    width: 30%;
  }

  .reading.pulse {
    margin-left: -5px;
  }
}

@media screen and (min-width:1266px) {
  .value-row {
    position: absolute;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    height: -16% !important;
    left: 38%;
    top: -1rem;
  }
}

@media screen and (max-width:1360px) {
  .box-alert {
    font-size: 14px;
    padding: 5px 20px;
  }
}

@media screen and (max-width:1360px) {
  .readings-size {
    font-size: 16px;
  }

  .readings-size {
    margin-top: 14px;
  }

  .reaction-text strong {
    max-width: 160px;
  }

  .bp-2 {
    vertical-align: top;
  }
}

@media screen and (max-width:1320px) {
  .readings {
    width: 51px;
    height: 34px;
  }

  .readings-gap {
    margin-right: 25px;
  }

  .latest-call p,
  .latest-vital p {
    font-size: 15px;
  }

  /* .tooltip-img {
    margin-top: -13px;
  } */
}

@media screen and (max-width:1228px) {
  .bp-2 {
    vertical-align: middle;
  }
}

@media screen and (max-width:1200px) {
  .latest-vital {
    margin-right: 30px;
  }

  .readings.bp {
    width: 75px;
  }

  .box-alert {
    padding: 5px 21px;
  }

  /* .reaction-text strong {
    max-width: 160px;
  } */

  /* .tooltip-img {
    margin-top: -15px;
  } */
  .healtvc-card-title {
    font-size: 1.37vw !important;
  }
}

@media screen and (max-width:1160px) {
  .readings-gap {
    margin-right: 25px;
  }
}

@media screen and (max-width: 1700px) {
  .healtvc-card-title {
    font-size: 1.1vw;
  }

  /* .tooltip-img {
    margin-top: -10px;
  } */
}

/** Media Queries for Width **/

/** Media Queries for Height **/

@media screen and (min-height:1200px) {
  .powered {
    margin-top: 17rem;
  }

  .login-logo {
    margin-top: 8rem;
  }

  .popup-content.homecare-charts-modal-content {
    min-height: 38%;
  }
}

@media screen and (max-height:930px) {
  .powered {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  /* .login-logo {
    margin-top: 5.7rem;
  } */
  .popup-content.homecare-charts-modal-content {
    min-height: 50%;
  }
}

@media screen and (max-height: 840px) {

  .cf_wrapper {
    height: calc(100vh - 180px)
  }

  .pdf {
    height: 24px;
  }

  .login-section {
    transform: scale(.8) translateY(-10px);
  }

  /* .notes-conent {
    flex-flow: row nowrap !important;
  } */

  /* .notes-conent>div {
    margin: 0 !important;
    max-height: 100% !important;
  } */

  /* .notes-conent>div:first-child {
    margin-right: 12px !important;
  } */
/*
  .notes-conent>.doctor-notes:nth-child(2) label.label1 {
    font-size: .9vw;
  } */

  /* .notes-conent>.doctor-notes {
    max-height: calc((100% / 1.5) - .5rem);
  } */

  .notes-conent>.doctor-notes {
    max-height: calc(100% - 77px);
  }

  /* .notes-conent>.doctor-notes:nth-child(2) {
    max-height: 65px;
  } */

  /* .cf_wrapper>.row:nth-child(2) {
    max-height: calc(100% - 140px);
  } */

  .doctor-notes label.label1 {
    margin-top: 4px !important;
  }

  label.label1 {
    font-size: 1.9vh;
  }

  .patient-vitals label.label1 {
    margin-top: 0 !important;
  }
}

@media screen and (max-height: 780px) {
  span.pv-title {
    line-height: 13px;
  }

  .patient-vitalslist li>span {
    font-size: 13px;
  }

  .patient-vitalsection>.row>div:first-child>.row>div:last-child {
    height: calc(100% - (100% / 2.30));
  }
}

@media screen and (max-height: 740px) {
  /* .doc-notes-text {
    max-height: 150px;
  } */

  /* .notes-conent {
    flex-flow: row nowrap !important;
  } */

  /* .notes-conent>div {
    margin: 0 !important;
    max-height: 100% !important;
  } */

  /* .notes-conent>div:first-child {
    margin-right: 12px !important;
  } */

  /* .notes-conent>.doctor-notes:nth-child(2) label.label1 {
    font-size: 11px;
  } */

  label.label1 {
    font-size: 14px;
  }

  .cf_wrapper {
    min-height: 560px;
  }

  .login-section {
    transform: scale(.8) translateY(-30px);
  }
}

@media screen and (max-height:930px) {
  .powered {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .patient-vitalslist li>span {
    height: calc(100% / 6.5);
  }

  .patient-vitalsection>.row>div:first-child>.row>div:last-child {
    height: calc(100% - (100% / 2.30));
  }

  .cf_wrapper>.row:last-child {
    position: fixed;
    bottom: 0;
    text-align: center;
    left: 0;
    right: 0;
    padding: 5px 10px;
    background-color: white;
    box-shadow: 0px -2px 10px 0px rgb(0 0 0 / .1);
  }

  .cf_wrapper>.row:nth-child(2) {
    margin-bottom: 50px;
  }

  .cf_wrapper>.row:last-child>div {
    max-width: 1580px;
    margin: 0 auto;
    text-align: left;
  }

  .cf_wrapper.hc_wrapper {
    height: initial;
  }

  .cf_wrapper.hc_wrapper>.row:nth-child(2) {
    max-height: initial;
    min-height: 450px;
  }
}

/** Media Queries for Height **/

@media screen and (max-width: 1580px) {
  @media screen and (max-height: 740px) {
    .cf_wrapper>.row:last-child>div {
      max-width: 96%;
      text-align: left;
    }
  }
}